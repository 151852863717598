import React from "react";
import cx from "classnames";
import payrollLogo from "../../../../images/payroll-logo.png";
import Container from "../../../../components/Container";

import * as styles from "./style.module.scss"

const Header = () => {
  return (
    <header
      className={cx(
        styles.Header,
        "px-30px py-4 bg-white text-center sticky top-0 z-50"
      )}
    >
      <Container>
        <img
          src={payrollLogo}
          alt="RazorpayX Payroll"
          className={cx("mx-auto", styles.PayrollLogo)}
        />
        <span className="font-bold">Three clicks, payroll fixed.</span>
      </Container>
    </header>
  );
};

export default Header;
