import React from "react";
import ExternalLink from "../components/ExternalLink";

export const FAQs = [
  {
    id: 0,
    title: "What is RazorpayX Payroll",
    answer: (
      <>
        One of the most efficient and reliable payroll software in India for
        startups and businesses.
        <br />
        <br />
        RazorpayX Payroll software automates the payroll process for you, so you
        can calculate payroll and disburse salaries quickly. Furthermore, we
        handle all payments and filings of compliances like TDS, PF, ESI and PT.
      </>
    ),
  },
  {
    id: 1,
    title:
      "How is RazorpayX Payroll different from the other payroll softwares?",
    answer: (
      <>
        Most payroll softwares as payroll processing tools where you need to do
        most of the work since they give you reports. You then need to go and
        upload these to your bank to make salary transfers, government portals
        for compliance payments.
        <br />
        <br />
        RazorpayX Payroll automates salary transfers to any bank in India. We
        also take care payments and filings for all compliances like TDS, PF, PT
        and ESI
      </>
    ),
  },
  {
    id: 2,
    title: "What can I do with RazorpayX Payroll?",
    answer: (
      <>
        RazorpayX Payroll is a one-stop solution for your organization. It has
        the following modules to ensure all employee operations are done in a
        single tool
        <br />
        <br />
        <ul className="list-disc ml-4">
          <li>Salary Calculations and Transfers</li>
          <li>Statutory Compliance Payments and Filing</li>
          <li>Reimbursements request, approval and payment</li>
          <li>Leave and Attendance System</li>
          <li>Multiple letter generator tools</li>
          <li>Group Health Insurance for employees</li>
        </ul>
        <br />
        and much more
      </>
    ),
  },
  {
    id: 3,
    title: "How much does RazorpayX Payroll cost?",
    answer: (
      <>
        RazorpayX Payroll works on a flat pricing in the Pro Plan which costs
        you Rs.100/month/employee where we take care of all compliance payments
        and filings for you. Everything is included for no extra cost. No hidden
        fees, surprises or corporate trickery.
        <br />
        <br />
        The Free plan of RazorpayX Payroll is really free with absolutely no
        limitations. You also get the first month of Pro Plan as a trial. No
        further conditions applied.
      </>
    ),
  },
  {
    id: 4,
    title: "Does RazorpayX Payroll integrate with Slack?",
    answer: (
      <>
        Yes, RazorpayX Payroll does integrate with Slack. With the RazorpayX
        Payroll Slack app, you can execute common HR & Payroll tasks related to
        leaves, reimbursements, payslips etc right from inside Slack. You can
        know more about it{" "}
        <ExternalLink href="https://intercom.help/XPayroll/en/articles/5264610-slack-app-for-xpayroll-a-guide">
          here
        </ExternalLink>
        .
      </>
    ),
  },
];
