import React from "react";
import chevronDown from "../../images/icons/chevron_down.svg";
import { FAQProvider, useFAQValue } from "./context";
import cx from "classnames";
import useWindowSize from "../../utils/useWindowSize";
import * as styles from "./styles.module.scss";

export const FAQItem = ({ title, value, answer }) => {
  const faqValue = useFAQValue();

  const handleOnClick = () => {
    faqValue.onChange && faqValue.onChange(value);
  };

  const isActive = value === faqValue.value;

  return (
    <>
      <span
        className={cx(
          "p-4 font-bold hover:bg-background-tertiary",
          "flex justify-between cursor-pointer",
          {
            "bg-background-tertiary":
              isActive || !faqValue.shouldShowDesktopVersion,
          },
          {
            [styles.isActive]: isActive,
          },
          styles.FAQItem
        )}
        onClick={handleOnClick}
      >
        <div>
          <span
            className={cx(styles.Bullet, {
              [styles.isActive]: isActive,
            })}
          ></span>
          <span>{title}</span>
        </div>
        <img src={chevronDown} className={styles.ExpandIcon} alt="" />
      </span>
      {!faqValue.shouldShowDesktopVersion && (
        <span
          className={cx("bg-background-alternate", styles.FAQAnswerMobile, {
            [styles.isActive]: isActive,
          })}
        >
          <span className="inline-block p-4">{answer}</span>
        </span>
      )}
    </>
  );
};

const FAQDesktop = ({ children, activeChild }) => {
  return (
    <div>
      <span className={styles.FAQLeft}>{children}</span>
      <span
        className={cx(
          "bg-background-tertiary border border-primary shadow-l-lg",
          styles.FAQRight
        )}
      >
        <span className="font-bold text-xl">{activeChild.props.title}</span>
        <br />
        <span className={cx(styles.Puck)}></span>
        <br />
        <span className="text-sm">{activeChild.props.answer}</span>
      </span>
    </div>
  );
};

const FAQMobile = ({ children }) => {
  return <div className="rounded-md flex flex-col">{children}</div>;
};

const FAQ = ({ children, className = "", value, onChange }) => {
  const windowSize = useWindowSize();
  const shouldShowDesktopVersion = windowSize.width >= 768;
  const context = React.useMemo(() => {
    return { value, onChange, shouldShowDesktopVersion };
  }, [value, shouldShowDesktopVersion]);
  const activeChild = children.find((child) => child.props.value === value);

  return (
    <FAQProvider value={context}>
      <div className={cx(className)}>
        {/*show desktop version from ipad resolution*/}
        {shouldShowDesktopVersion ? (
          <FAQDesktop activeChild={activeChild}>{children}</FAQDesktop>
        ) : (
          <FAQMobile>{children}</FAQMobile>
        )}
      </div>
    </FAQProvider>
  );
};

export default FAQ;
