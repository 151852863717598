import setCookie from "./setCookie";
import getCookie from "./getCookie";

const UTM = {
  SOURCE: "utm_source",
  MEDIUM: "utm_medium",
  CAMPAIGN: "utm_campaign",
  CONTENT: "utm_content",
  TERM: "utm_term",
};

const attributionCookie = "attribution";

function getUTMfromReferer() {
  const url = document.referrer;
  if (!url || typeof url !== "string") return null;

  let domainsMap = {
    organic: {
      Google: ["google"],
      Bing: ["bing.com"],
      Yahoo: ["yahoo."],
      AOL: ["aol.com"],
      Ask: ["ask.com"],
      Baidu: ["baidu.com"],
      DuckDuckGo: ["duckduckgo.com"],
      Yandex: ["yandex."],
    },
    social: {
      Facebook: ["lm.facebook", "l.facebook", "m.facebook", "facebook.com"],
      Linkedin: ["m.linkedin", "linkedinpost", "in.linkedin", "linkedin.com"],
      Quora: ["quora.com"],
      Reddit: ["m.reddit", "reddit.com"],
      Twitter: ["t.co/", "twitter.com", "mobile.twitter", "m.twitter"],
      Medium: ["medium.com"],
    },
  };

  for (var source in domainsMap) {
    let domains = Object.keys(domainsMap[source]);

    for (let i = 0; i < domains.length; i++) {
      let domainUrls = domainsMap[source][domains[i]];
      for (let j = 0; j < domainUrls.length; j++) {
        if (url.indexOf(domainUrls[j]) >= 0) {
          return {
            source: domains[i].toLowerCase(),
            medium: source,
          };
        }
      }
    }
  }

  return null;
}

const getUtmParams = () => {
  let url = "";
  let utmFromReferrer = null;
  let utmCookie = null;

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
    utmFromReferrer = getUTMfromReferer();
    try {
      utmCookie = JSON.parse(getCookie(attributionCookie));
    } catch (e) {
      console.error(e);
    }
  }

  if (utmCookie?.utm_source) {
    return utmCookie;
  }

  const urlParams = new URLSearchParams(url.search);
  const utmSource = urlParams.get(UTM.SOURCE) || "";
  const utmMedium = urlParams.get(UTM.MEDIUM) || "";
  const utmCampaign = urlParams.get(UTM.CAMPAIGN) || "";
  const utmContent = urlParams.get(UTM.CONTENT) || "";
  const utmTerm = urlParams.get(UTM.TERM) || "";

  const rTag = urlParams.get("r") || "";

  let source = utmSource,
    medium = utmMedium,
    campaign = utmCampaign,
    content = utmContent,
    term = utmTerm;

  if (utmFromReferrer) {
    source = utmFromReferrer.source;
    medium = utmFromReferrer.medium;
  }

  if (utmSource) {
    source = utmSource;
    medium = utmMedium;
    campaign = utmCampaign;
    content = utmContent;
    term = utmTerm;
  }

  const utmData = {
    utm_source: source,
    utm_medium: medium,
    utm_campaign: campaign,
    utm_content: content,
    utm_term: term,
    rTag,
  };

  if (source) {
    setCookie(attributionCookie, JSON.stringify(utmData), 30);
  }

  return utmData;
};

const propagateUtmToLink = (link) => {
  const utmParams = getUtmParams() || {};
  let updatedLink = link;
  try {
    const url = new URL(link);
    const urlParams = new URLSearchParams(url.search);
    if (!urlParams.get("utm_source")) {
      if (!!utmParams.utm_source) {
        urlParams.append("utm_source", utmParams.utm_source);
      }
      if (!!utmParams.utm_campaign) {
        urlParams.append("utm_campaign", utmParams.utm_campaign);
      }
      if (!!utmParams.utm_medium) {
        urlParams.append("utm_medium", utmParams.utm_medium);
      }
      if (!!utmParams.utm_term) {
        urlParams.append("utm_term", utmParams.utm_term);
      }
      if (!!utmParams.utm_content) {
        urlParams.append("utm_content", utmParams.utm_content);
      }
      if (!!utmParams.rTag) {
        urlParams.append("r", utmParams.rTag);
      }
      url.search = urlParams.toString();
      updatedLink = url.href;
    }
  } catch (e) {
    console.error(e);
  }

  return updatedLink;
};

export default propagateUtmToLink;
