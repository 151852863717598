import cx from 'classnames';
import React from 'react';
import heroImage from '../../../../images/hero.jpg';
import propagateUtmToLink from '../../../../utils/propagateUtmToLink';
import noteWaveVideo from '../../../../videos/note-wave.mp4';
import Button from '../../../Button';

import * as styles from './style.module.scss';

const payrollURL = process.env.GATSBY_PAYROLL_URL;

const Hero = () => {
  const [payrollSignupUrl, setPayrollSignupUrl] = React.useState('');

  React.useEffect(() => {
    setPayrollSignupUrl(propagateUtmToLink(`${payrollURL}/signup`));
  }, []);

  return (
    <section className={cx('px-30px', styles.HeroSection)}>
      <h1 className={cx('text-center', styles.HeroSectionTitle)}>
        <span className="font-bold">Dear founder,</span> let's get your payroll
        fixed.
      </h1>

      <div className="flex flex-col justify-center items-center align-center mt-12 flex-wrap text-center">
        <div
          style={{ backgroundImage: `url(${heroImage}` }}
          className={cx(
            styles.HeroImageContainer,
            'shadow-xl bg-cover bg-no-repeat'
          )}
        >
          <video
            poster={heroImage}
            autoPlay
            loop
            muted
            playsInline
            className={cx(styles.HeroImage, 'mx-auto')}
          >
            <source src={noteWaveVideo} />
          </video>
        </div>
        <h4 className={cx(styles.ThreeClicks)}>Three clicks, payroll fixed.</h4>
        <div className="sm:px-0 relative mt-7">
          {payrollSignupUrl && (
            <a href={payrollSignupUrl}>
              <Button>Sign Up</Button>
            </a>
          )}
        </div>
      </div>
    </section>
  );
};

export default Hero;
