import React from "react";
import cx from "classnames";
import * as styles from "./style.module.scss";

const Button = (props) => {
  return (
    <button
      {...props}
      className={cx(
        "bg-primary text-white text-center",
        "px-8 py-2",
        "rounded-sm",
        styles.Button,
        props.className
      )}
    >
      {props.children}
    </button>
  );
};

export default Button;
