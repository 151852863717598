import React from "react";
import IframeResizer from "iframe-resizer-react";
import LazyLoad from "react-lazyload";
import cx from "classnames";
import Container from "../../../../components/Container";

import * as styles from "./style.module.scss";

const WallOfLove = () => {
  return (
    <section
      className={cx(
        "bg-background px-30px text-center",
        styles.WallOfLoveSection
      )}
    >
      <Container>
        <h3 className="text-42px font-bold text-center mb-10">Wall of Love</h3>
        <LazyLoad height={1000} offset={600} once>
          <IframeResizer
            src="https://shoutout.io/embed/86c99f7f-e914-4454-b23b-a453e15e6b7f"
            frameBorder={0}
            width="100%"
            scrolling="yes"
            title="Wall of love"
            id="shoutout-iframe"
          />
        </LazyLoad>
      </Container>
    </section>
  );
};

export default WallOfLove;
