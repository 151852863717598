import React from "react";
import FAQ, { FAQItem } from "../../../FAQ";
import { FAQs as FAQData } from "../../../../data/faqs";
import cx from "classnames";
import Container from "../../../../components/Container";

import * as styles from "./style.module.scss";

const FAQs = () => {
  const [faqValue, setFAQValue] = React.useState(0);

  const handleFAQChange = React.useCallback((value) => {
    setFAQValue(value);
  }, []);
  return (
    <section
      className={cx("bg-background px-30px", styles.FAQSection)}
      id="faq"
    >
      <Container>
        <h3 className="text-4xl font-black text-center mb-20 hidden md:block">
          Frequently asked questions
        </h3>
        <h3 className="text-3xl font-black text-center mb-9 md:hidden sm:block">
          FAQs
        </h3>
        <div>
          <FAQ value={faqValue} onChange={handleFAQChange}>
            {FAQData.map((faq) => (
              <FAQItem
                value={faq.id}
                title={faq.title}
                answer={faq.answer}
                key={faq.id}
              />
            ))}
          </FAQ>
        </div>
      </Container>
    </section>
  );
};

export default FAQs;
