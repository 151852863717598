import React from "react";
import cx from "classnames";
import Container from "../../../../components/Container";
import Button from "../../../../components/Button";
import quickGuidePoster from "../../../../images/guide-poster.png";
import playIcon from "../../../../images/icons/play.svg";
import guideVideo from "../../../../videos/quick-guide.mp4";

import * as styles from "./style.module.scss";

const QuickGuide = () => {
  const [shouldShowGuideControls, setShouldShowGuideControls] =
    React.useState(false);
  const guideVideoRef = React.useRef(null);

  const handlePlay = () => {
    guideVideoRef.current && guideVideoRef.current.play();
    setShouldShowGuideControls(true);
  };

  return (
    <section
      className={cx("bg-background-alternate", styles.QuickGuideSection)}
    >
      <Container>
        <div
          className={cx(
            "flex flex-col items-center",
            styles.QuickGuideContainer
          )}
        >
          <h4 className={cx(styles.QuickGuideText)}>
            We are not just saying,{" "}
            <span className="text-center block md:text-left md:inline">
              have a look..
            </span>
          </h4>
          <div className={cx(styles.QuickGuideVideo)}>
            <video
              preload="none"
              poster={quickGuidePoster}
              width="100%"
              controls={shouldShowGuideControls}
              ref={guideVideoRef}
            >
              <source type="video/mp4" src={guideVideo} />
            </video>
            {!shouldShowGuideControls && (
              <div
                className={styles.QuickGuideVideoControls}
                onClick={handlePlay}
              >
                <img alt="Play" src={playIcon} />
              </div>
            )}
          </div>
          <a href="#fix-payroll">
            <Button>I want my payroll fixed</Button>
          </a>
        </div>
      </Container>
    </section>
  );
};

export default QuickGuide;
