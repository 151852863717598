import React from "react";

const FAQContext = React.createContext(undefined);

export const FAQProvider = ({ value, children }) => {
  return <FAQContext.Provider value={value}>{children}</FAQContext.Provider>;
};

export const useFAQValue = () => {
  const context = React.useContext(FAQContext);
  if (context === undefined) {
    throw new Error("useFAQValue must be used within a FAQProvider");
  }
  return context;
};
