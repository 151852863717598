import React from "react";
import cx from "classnames";
import propagateUtmToLink from "../../utils/propagateUtmToLink";

const classMap = {
  normal: "text-primary",
  alternate: "text-mana-blue",
};

const InternalLink = ({ type = "normal", className, ...props }) => {
  const [url, setUrl] = React.useState(props.href);

  React.useEffect(() => {
    setUrl(propagateUtmToLink(url));
  }, []);

  return <a {...props} href={url} className={cx(classMap[type], className)} />;
};

export default InternalLink;
