import React from "react";
import Container from "../Container";
import InternalLink from "../InternalLink";
import cx from "classnames";
import ExternalLink from "../ExternalLink";
import twitterIcon from "../../images/icons/twitter.png";
import instagramIcon from "../../images/icons/instagram.png";
import linkedInIcon from "../../images/icons/linkedin.png";

import * as styles from "./index.module.scss";

const payrollURL = process.env.GATSBY_PAYROLL_URL;

const features = [
  {
    label: "HR Features",
    link: "https://razorpay.com/payroll/hr-software/",
  },
  {
    label: "Leave & attendance management",
    link: "https://razorpay.com/payroll/leave-management-system/",
  },
  {
    label: "Payroll processing",
    link: "https://razorpay.com/payroll/payroll-processing/",
  },
  {
    label: "Payroll compliance",
    link: "https://razorpay.com/payroll/payroll-compliance/",
  },
  {
    label: "Group health insurance",
    link: "https://razorpay.com/group-health-insurance/",
  },
  {
    label: "Slack integration",
    link: "https://razorpay.com/payroll/slack/",
  },
];

const Footer = () => {
  return (
    <footer className="bg-white py-16 px-30px">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-6 gap-8">
          <div className="md:col-span-2">
            <div className="text-lighter-grey font-bold">
              About RazorpayX Payroll
            </div>
            <div className="text-lighter-grey mt-4">
              Calculate payroll and disburse salaries in just a few clicks.
              RazorpayX Payroll automates payments and filings of compliances
              like TDS, PF, ESI, PT, and more.{" "}
              <InternalLink
                type="alternate"
                href={`${payrollURL}/signup`}
                target="_blank"
              >
                Sign Up Now
              </InternalLink>{" "}
              and get started!
            </div>
            <div className="text-lighter-grey mt-8">
              To know more about RazorpayX Payroll visit:{" "}
              <InternalLink
                type="alternate"
                href="https://razorpay.com/payroll"
                target="_blank"
              >
                https://razorpay.com/payroll
              </InternalLink>
            </div>
          </div>
          <div className="md:col-span-2">
            <div className="text-lighter-grey font-bold">Product Features</div>
            <div className="mt-4">
              {features.map((feature) => (
                <div key={feature.label} className={cx(styles.Link)}>
                  <InternalLink
                    type="alternate"
                    href={feature.link}
                    target="_blank"
                  >
                    {feature.label}
                  </InternalLink>
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className="text-lighter-grey font-bold">Quick Links</div>
            <div className="mt-4">
              <ExternalLink
                className={cx(styles.Link, "inline-block")}
                type="alternate"
                href="https://intercom.help/XPayroll/en/"
                target="_blank"
              >
                Help
              </ExternalLink>
              <br />
              <ExternalLink
                className={cx(styles.Link, "inline-block")}
                type="alternate"
                href="#faq"
                target="_self"
              >
                FAQs
              </ExternalLink>
              <br />
              <InternalLink
                className={cx(styles.Link, "inline-block")}
                type="alternate"
                href="https://razorpay.com/blog/category/payroll/"
                target="_blank"
              >
                Blog
              </InternalLink>
            </div>
          </div>
          <div>
            <div className="flex">
              <ExternalLink href="https://twitter.com/razorpayx">
                <img
                  alt="Twitter"
                  className={cx(styles.SocialIcon, "mr-5")}
                  src={twitterIcon}
                />
              </ExternalLink>
              <ExternalLink href="https://www.instagram.com/razorpayx">
                <img
                  alt="Instagram"
                  className={cx(styles.SocialIcon, "mr-5")}
                  src={instagramIcon}
                />
              </ExternalLink>
              <ExternalLink href="https://in.linkedin.com/company/razorpay">
                <img
                  alt="Linkedin"
                  className={cx(styles.SocialIcon)}
                  src={linkedInIcon}
                />
              </ExternalLink>
            </div>
          </div>
        </div>
        <div className={cx(styles.Copyright, "text-lighter-grey")}>
          Copyright © {new Date().getFullYear()}  RazorpayX Payroll.{" "}
          <span className="block md:inline text-lighter-grey">All Rights reserved.</span>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
