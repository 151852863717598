import React from "react";
import { Helmet } from "react-helmet";

const metaDescription =
  "Fix your payroll in three clicks with RazorpayX Payroll software. Sign up and get 3 months of Pro RazorpayX Payroll plan for free!";
const metaTitle =
  "RazorpayX Payroll Software - Three Clicks and Get Your Payroll Fixed";

const Meta = () => {
  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta
        name="facebook-domain-verification"
        content="9d5y3o4yn8ycn24s4otz9hmin6hppk"
      />
      <meta name="description" content={metaDescription} />
      <meta property="og:url" content="https://payrollfixed.com/" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta
        property="og:image"
        content="https://payrollfixed.com/assets/images/og-image.png"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="payrollfixed.com" />
      <meta property="twitter:url" content="https://payrollfixed.com/" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta
        name="twitter:image"
        content="https://payrollfixed.com/assets/images/og-image.png"
      />
      <link rel="canonical" href={process.env.GATSBY_SELF_CANONICAL_URL} />
    </Helmet>
  );
};

export default Meta;
