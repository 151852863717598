import * as React from "react";
import Footer from "../components/Footer";
import Hero from "../components/folds/index/Hero";
import QuickGuide from "../components/folds/index/QuickGuide";
import WallOfLove from "../components/folds/index/WallOfLove";
import Header from "../components/folds/index/Header";
import FAQs from "../components/folds/index/FAQs";
import Meta from "../components/folds/index/Meta";

const IndexPage = () => {
  return (
    <>
      <Meta />
      <main className="bg-background-primary">
        <Header />
        <Hero />
        <QuickGuide />
        <WallOfLove />
        <FAQs />
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;
