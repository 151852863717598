function getCookie(name) {
  const cookieVal =
    (name = (document.cookie + ";").match(new RegExp(name + "=.*;"))) &&
    name[0].split(/=|;/)[1];
  try {
    if (cookieVal) {
      // decodeURIComponent(null) returns `'null'` (string) so it can turn falsy values into truthy values.
      // This condition check avoids those issues and returns the falsy value.
      return decodeURIComponent(cookieVal);
    } else {
      return cookieVal;
    }
  } catch (err) {
    // `decodeURIComponent` shouldn't throw an error in most cases
    // this is for rare scenarios like, if some cookie has wrong encoding `%99999` etc ..
    // .. it can throw URI malformed error.
    console.error("[getCookie]: ", err.message, cookieVal);
    return cookieVal;
  }
}

export default getCookie;
