import React from "react";
import cx from "classnames";

const classMap = {
  normal: "text-primary",
  alternate: "text-mana-blue",
};

const ExternalLink = ({ type = "normal", className, ...props }) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      {...props}
      className={cx(classMap[type], className)}
    />
  );
};

export default ExternalLink;
